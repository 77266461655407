html {
  scroll-behavior: smooth;
}

.bgwhitetransparent{
  background-color:rgba(255,255,255,.85)
}

a:hover{
  text-decoration: none;
}

body{
  letter-spacing: 1px;
  color:#6e7a8c
}

.form-control{
  color:#6e7a8c
}

.title{
  letter-spacing: 3px;
}

.color1, a.color1:hover, .page-link{
  color:#6e7a8c !important;
}

.allservices a, .home a{
  color:#bf956b;
}

.bgcolor1, .page-item.active .page-link{
  background-color:#6e7a8c
}

.page-item.active .page-link{
  color:#fff !important;
}

.page-item.active .page-link{
  border-color:transparent;
}

.bordercolor1{
  border:1px #6e7a8c solid;
}

ul{
  margin: 0;
  padding-left: 15px;
}

.btn-primary, .btn-primary:hover{
  background-color:#bf956b;
  border-color:#bf956b;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle{
  background-color:#bf956b;
  border-color:#bf956b;
}
.bgcolor1transparent{
  background-color:rgba(110,122,140,.85);
}

.bgcolor2 a{
  color: #e3d2c0;
}

button.link{
  display: block;
  width:100%;
  background: transparent;
  border:none;
  text-align: left;
  color:#bf956b;
  padding: 0;
}

.amountput{
  border-radius: 0;
}

.top{
  top:0;
}

.footer a{
  color:#6e7a8c
}

.footer.bgcolor1 a{
  color:#b4c0d2
}

.footer .langlink button{
  background-color: transparent;
  border: 0;
}

.color2{
  color:#bf956b
}

.color2:hover{
  color:#bf956b
}

.bgcolor2{
  background-color:#bf956b
}

.panoimg{
  height: 200px;
  background-position: center;
  background-size: cover;
}

.bordercolor2{
  border:1px #bf956b solid;
}

.bottom{
  bottom:0;
}

.color3{
  color:#e3d2c0
}

.color4,.color4:hover{
  color:#b4c0d2 !important;
}

.color3:hover{
  color:#e3d2c0
}

.bgcolor3{
  background-color:#e3d2c0
}

.bordercolor3{
  border:1px #e3d2c0 solid;
}

.bordercolor4{
  border:1px #b4c0d2 solid;
}

footer.bgcolor1 a,footer.bgcolor1 a:hover{
  color:#fff;
}

.slideimage{
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
}

.instalaciones .squareimaged{
  background-size: cover;
}

.squareimage, .bgimg{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.squareimaged{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.squareimaget{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.menubtntoggle button.togglebtnmenu,.menubtntoggle .menuhometoggle{
  background: transparent;
  border:none;
}

.btntogglecartoff{
  background: transparent;
  border: 0;
}

button:focus{
  outline:none !important
}

.badge {
  border-radius: 0 !important;
  line-height: 1.5;
}

.badge-light {
  background-color:#fff;
  color:#bf956b;
  border:1px solid #bf956b;
}

.btntogglecarton{
  color: #fff;
}

.square{
  width: 100%;
  padding-bottom: 151%;
}

.squared{
  width: 100%;
  padding-bottom: 90%;
}

.alert-info {
  color: #6b5742;
  background-color: #d5c6b8;
  border-color: #c8b39e;
}

.alert-warning{
  color: #45546b;
  background-color: #b7cbe9;
  border-color: #a6bad7;
}

.bg-primary {
  background-color: #6e7a8c !important;
}

.fadeOut{
  opacity:0;
  visibility: hidden;
  transition: all ease-out 0.45s;
}
.fadeIn{
  opacity:1;
  transition: all ease-in 0.45s;

}

img{
  max-width:100%;
}

blockquote{
  border-left: 5px solid;
  padding-left: 25px;
  margin: 30px 0;
}

.searcher{
  width: auto !important;
  border-radius: 0;
}

.pagination, .page-item:last-child .page-link, .page-item:first-child .page-link{
  border-radius: 0px !important;
}

.searcher::placeholder{
  color: #ccc;
}

.bgcartspaceall{
  position:relative;
}

.bgcartspace button.link{
  width: auto;
}

.headershop .pagina{
  align-items: center;
  justify-content: flex-end;
}

.headershop .pagesinfo{
  text-align: center;
}

.testimonials .carousel-indicators li{
  background-color: #6e7a8c;
}
.testimonials .carousel-indicators{
  bottom: -50px;
  justify-content: start;
  margin: 0;
}

.badge-success{
  border:1px solid #85d996;
  background-color: #efffed;
  color:#09825d;
}

.badge-danger{
  border:1px solid #fbb5b2;
  background-color: #fde2dd;
  color:#ed5f74;
}

.badge-warning{
  border:1px solid #F8E5BC;
  background-color: #fcf9e9;
  color:#d97917;
}

.badge-info{
  border:1px solid #f5fbff;
  background-color: #d6ecff;
  color:#3d4eac;
}

.badge-secondary{
  border:1px solid #c1c9d2;
  background-color: #f7fafc;
  color:#697386;
}

.text-decoration-line-through{
  text-decoration: line-through;
}

.opacity5{
  opacity: .5;
}

@media screen and (min-width:1700px) {
  .slideimage{
    background-size: 100% auto;
  }

  .square{
    padding-bottom: 120%;
  }
}


@media screen and (max-width:600px) {

  .bgcartspaceall{
    position:fixed;
    z-index: 999999;
    height: 100%;
    background: #fff;
  }

  .itemproduct, .itemservice{
    margin-top:10px;
    padding-top:10px;
    border-top: 1px dotted #b4c0d2;
  }

  .itemproduct .title, .itemservice .title{
    margin-top:25px;
  }

  .textmenuitem{
    display: none;
  }

  .articleitem{
    margin-top:35px;
  }

  .headershop .pagina{
    align-items: center;
    justify-content: flex-start;
  }

  .headershop .pagesinfo{
    text-align: left;
  }

  .articlespacecontet{
    margin-left: 25px;
  }

}

@media screen and (min-width:1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1350px;
  }
}

@media screen and (min-width:1500px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 70%;
  }
}